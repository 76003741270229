<template>
    <div class="row" v-if="show">
        <div class="col-md-6 text-center">
            <h3>Penjualan</h3>
            <DashboardDoughnut 
                :chartId="order.id"
                :chartData="order.data"
                :datasetIdKey="order.datasetIdKey"
                :width="chart.width"
                :height="chart.height"
                :cssClasses="chart.cssClasses"
                :styles="chart.styles"
                :plugins="chart.plugins"
            />
        </div>
        <div class="col-md-6 text-center">
            <h3>Pengembalian</h3>
            <DashboardDoughnut 
                :chartId="retur.id"
                :chartData="retur.data"
                :datasetIdKey="retur.datasetIdKey"
                :width="chart.width"
                :height="chart.height"
                :cssClasses="chart.cssClasses"
                :styles="chart.styles"
                :plugins="chart.plugins"
            />
        </div>
        <div class="col-md-6 text-center p-4">
            <h3>Proporsi penjualan dan pengembalian</h3>
            <DashboardPie 
                :chartId="total.id"
                :chartData="total.data"
                :datasetIdKey="total.datasetIdKey"
                :width="chart.width"
                :height="chart.height"
                :cssClasses="chart.cssClasses"
                :styles="chart.styles"
                :plugins="chart.plugins"
            />
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import { mapState } from 'vuex'
    import DashboardPie from "./DashboardPie.vue"
    import DashboardDoughnut from "./DashboardDoughnut.vue"

    var moment = require('moment');

    export default {
        props: ['show', 'selectedStartDate', 'selectedMonth'],

        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo
            })
        },

        data: function () {
            return {
                order: {
                    id: 'order',
                    datasetIdKey: 'order',
                    data: {
                        labels: [],
                        datasets: [{
                            backgroundColor: [],
                            data: []
                        }]
                    },
                },

                retur: {
                    id: 'return',
                    datasetIdKey: 'return',
                    data: {
                        labels: [],
                        datasets: [{
                            backgroundColor: [],
                            data: []
                        }]
                    },
                },

                total: {
                    id: 'total',
                    datasetIdKey: 'total',
                    data: {
                        labels: ['Jumlah Penjualan', 'Jumlah Pengembalian'],
                        datasets: [{
                            backgroundColor: ['#198754', '#dc3545'],
                            data: []
                        }]
                    },
                },

                chart: {
                    width: 400,
                    height: 400,
                    cssClasses: String(),
                    styles: Object(),
                    plugins: Array(),
                },
            }
        },


        watch: {

        },

        mounted() {

        },

        methods: {
            loadDataSalesReturnByDay: async function(filterBy) {
                const params = Object()
                
                if(filterBy == 'month') {
                    var selectedMonth = this.selectedMonth.split('-');

                    params.startDate = moment([selectedMonth[0], selectedMonth[1] - 1]).startOf('month').format();
                    params.endDate = moment([selectedMonth[0], selectedMonth[1] - 1]).endOf('month').format();
                } else {
                    params.startDate = this.selectedStartDate ? moment(this.selectedStartDate).format() : ""
                    params.endDate = this.selectedStartDate ? moment(this.selectedStartDate).endOf('day').format() : ""
                }

                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/sessioninfo/sales-return/day', {
                        params
                    })

                    let tempLabelOrder = Array()
                    let tempDataSetOrder = Array()
                    let tempColorsOrder = Array()

                    let tempLabelReturn = Array()
                    let tempDataSetReturn = Array()
                    let tempColorsReturn = Array()

                    data.byOperator.forEach(e => {
                        tempLabelOrder.push(e._id)
                        tempDataSetOrder.push(e.countOrder)
                        tempColorsOrder.push(this.setOperatorColor(e._id))

                        tempLabelReturn.push(e._id)
                        tempDataSetReturn.push(e.countReturn)
                        tempColorsReturn.push(this.setOperatorColor(e._id))
                    })

                    this.total.data.datasets[0].data = [
                        data.allCount[0] ? data.allCount[0].countOrder : 0, 
                        data.allCount[0] ? data.allCount[0].countReturn : 0
                    ]

                    this.order.data.labels = tempLabelOrder
                    this.order.data.datasets[0].data = tempDataSetOrder
                    this.order.data.datasets[0].backgroundColor = tempColorsOrder

                    this.retur.data.labels = tempLabelReturn
                    this.retur.data.datasets[0].data = tempDataSetReturn
                    this.retur.data.datasets[0].backgroundColor = tempColorsReturn

                } catch (error) {
                    console.log("error sales return by day", error);
                }
            },

            setOperatorColor: function(id) {
                // '#198754' hijau, 
                // '#0dcaf0' biru,
                // '#dc3545' merah,
                // '#ced4da' abu,

                // '#0d6efd' biru tua (Add)
                // '#6732d7' ungu (Add)
                // '#eda247' orange (Add)
                // '#fc1fa9' pink (Add)
                // '#654321' coklat (Add)
                // '#003366' navy (Add)
                // '#065535' hijau tua
                // '#ccff00' stabilo
                // '#66cccc' telor asin
                
                if(id == 'resti_nurjamilah') {
                    // pink
                    return '#fc1fa9'
                } else if(id == 'sahara2') {
                    // ungu
                    return '#6732d7'
                } else if(id == 'desni_aghni_amalinisa') {
                    // biru tua
                    return '#0d6efd'
                } else if(id == 'ulfiah_alamatus_sa_adah') {
                    // navy
                    return '#003366'
                } else if(id == 'selindri_nurifatikah3') {
                    // orange
                    return '#eda247'
                } else if(id == 'redi') {
                    // coklat
                    return '#654321'
                } else if(id == 'ingga_bia_putri') {
                    // stabilo
                    return '#ccff00'
                } else if(id == 'hervin') {
                    // telor asin
                    return '#66cccc'
                } else {
                    // hijau
                    return '#198754'
                }
            },
        },

        components: {
            DashboardPie,
            DashboardDoughnut
        },

    }

</script>
