<template>
    <div class="row" v-if="show">
        <div class="col-md-6 text-center p-4">
            <h3>Proporsi Tipe Agent</h3>
            <DashboardPie 
                :chartId="total.id"
                :chartData="total.data"
                :datasetIdKey="total.datasetIdKey"
                :width="chart.width"
                :height="chart.height"
                :cssClasses="chart.cssClasses"
                :styles="chart.styles"
                :plugins="chart.plugins"
            />
        </div>
        <div class="col-md-6 text-center p-4">
            <v-autocomplete class="label-label mb-0"
                v-model="selectedOperator"
                :items="operatorList" 
                hide-selected
                item-text="name"
                item-value="_id"
                label="Operator"
                :loading="isLoadingTotalOp"
            ></v-autocomplete>
            <h3 v-if="!isLoadingTotalOp">{{ selectedOperator }}</h3>
            <DashboardPie 
                :chartId="totalOp.id"
                :chartData="totalOp.data"
                :datasetIdKey="totalOp.datasetIdKey"
                :width="chart.width"
                :height="chart.height"
                :cssClasses="chart.cssClasses"
                :styles="chart.styles"
                :plugins="chart.plugins"
            />
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import { mapState } from 'vuex'

    // import DashboardBar from "./DashboardBar.vue"
    import DashboardPie from "./DashboardPie.vue"
    // import DashboardDoughnut from "./DashboardDoughnut.vue"
    // import DashboardLine from "./DashboardLine.vue"

    var moment = require('moment');

    export default {
        props: ['show', 'selectedType', 'selectedStartDate', 'selectedFilter', 'startDate', 'selectedMonth'],

        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo,
                operatorList: state => state.operator.operatorList,
            })
        },

        data: function () {
            return {
                selectedOperator: String(),

                isLoadingTotalOp: false,

                chart: {
                    width: 400,
                    height: 400,
                    cssClasses: String(),
                    styles: Object(),
                    plugins: Array(),
                },

                total: {
                    id: 'total',
                    datasetIdKey: 'total',
                    data: {
                        labels: [],
                        datasets: [{
                            backgroundColor: ['#065535', '#003366', '#6732d7', '#0d6efd'],
                            data: []
                        }]
                    },
                },

                totalOp: {
                    id: 'total',
                    datasetIdKey: 'total',
                    data: {
                        labels: [],
                        datasets: [{
                            backgroundColor: ['#065535', '#003366', '#6732d7', '#0d6efd'],
                            data: []
                        }]
                    },
                },
            }
        },


        watch: {
            async selectedOperator(val) {
                if(val) {
                    await this.loadDataAgentByDay(val)
                } else {
                    this.totalOp = {
                        id: 'total',
                        datasetIdKey: 'total',
                        data: {
                            labels: [],
                            datasets: [{
                                backgroundColor: ['#065535', '#003366', '#6732d7', '#0d6efd'],
                                data: []
                            }]
                        }
                    }

                }

            }
        },

        mounted() {

        },

        methods: {
            loadDataAgentByDay: async function(operatorId) {
                const params = Object()
                
                if(this.selectedFilter == 'month') {
                    var selectedMonth = this.selectedMonth.split('-');

                    params.startDate = moment([selectedMonth[0], selectedMonth[1] - 1]).startOf('month').format();
                    params.endDate = moment([selectedMonth[0], selectedMonth[1] - 1]).endOf('month').format();
                } else {
                    params.startDate = this.selectedStartDate ? moment(this.selectedStartDate).format() : ""
                    params.endDate = this.selectedStartDate ? moment(this.selectedStartDate).endOf('day').format() : ""
                }

                if(operatorId) {
                    params.operatorId = operatorId
                    this.isLoadingTotalOp = true
                }

                try {
                    
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/sessioninfo/agent/day', {
                        params
                    })

                    if(!operatorId) {
                        const labels = this.formatComplainTypeEng(data)
    
                        this.total.data.labels = labels.map(e => e.label)
                        this.total.data.datasets[0].data = labels.map(e => e.count)
                    } else {
                        const labels = this.formatComplainTypeEng(data)
    
                        this.totalOp.data.labels = labels.map(e => e.label)
                        this.totalOp.data.datasets[0].data = labels.map(e => e.count)
                    }

                    this.isLoadingTotalOp = false
                } catch (error) {
                    console.log("error sales return by day", error);
                }
            },

            formatComplainTypeEng(data) {
                return data.map(e => {
                    if (e._id == 'INQUIRY') e.label = 'PERTANYAAN'
                    if (e._id == 'REGISTRATION') e.label = 'REGISTRASI'
                    if (e._id == 'SHOPPING') e.label = 'PEMBELANJAAN'
                    if (e._id == 'DEPOSIT') e.label = 'DEPOSIT'

                    return e
                })
            },
            
            setOperatorColor: function(id) {
                // '#198754' hijau, 
                // '#0dcaf0' biru,
                // '#dc3545' merah,
                // '#ced4da' abu,

                // '#0d6efd' biru tua (Add)
                // '#6732d7' ungu (Add)
                // '#eda247' orange (Add)
                // '#fc1fa9' pink (Add)
                // '#654321' coklat (Add)
                // '#003366' navy (Add)
                // '#065535' hijau tua
                // '#ccff00' stabilo
                // '#66cccc' telor asin
                
                if(id == 'resti_nurjamilah') {
                    // pink
                    return '#fc1fa9'
                } else if(id == 'sahara2') {
                    // ungu
                    return '#6732d7'
                } else if(id == 'desni_aghni_amalinisa') {
                    // biru tua
                    return '#0d6efd'
                } else if(id == 'ulfiah_alamatus_sa_adah') {
                    // navy
                    return '#003366'
                } else if(id == 'selindri_nurifatikah3') {
                    // orange
                    return '#eda247'
                } else if(id == 'redi') {
                    // coklat
                    return '#654321'
                } else if(id == 'ingga_bia_putri') {
                    // stabilo
                    return '#ccff00'
                } else if(id == 'hervin') {
                    // telor asin
                    return '#66cccc'
                } else {
                    // hijau
                    return '#198754'
                }
            },
        },

        components: {
            // DashboardBar,
            DashboardPie,
            // DashboardDoughnut,
            // DashboardLine
        },

    }

</script>
