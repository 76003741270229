<template>
    <v-dialog 
        v-model="showDialog"
        transition="dialog-bottom-transition" max-width="600"
        persistent
        @keydown.esc="closeDialog"
    >
        <template>
            <v-card>
                <v-toolbar color="deep-purple accent-4" dark class="text-h6 text-white" style="background-color:red">
                    <span class="text-white">Detail Label</span>
                </v-toolbar>
                <v-card-text class="mt-5">
                    <v-row>
                        <v-col class="">
                            <v-text-field v-model="term" :rules="rules.required" placeholder="Cari nama label"></v-text-field>
                        </v-col>
                        
                        <v-col class="pt-7">
                            <v-btn class="mr-2 text-white btn btn-primary" @click="handleSearch">
                                Cari
                            </v-btn>
                            <v-btn @click="resetFilter">
                                Reset
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headers" :items="desserts"
                        :options.sync="options" :server-items-length="totalElements"
                        class="elevation-1" :loading="isLoading"
                        :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
                    >
                        <template v-slot:item.action="{ item }">
                            <a @click="goRoomChat(item)" size="small" variant="outlined">
                               Lihat Percakapan
                            </a>
                            
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions class="justify-end">
                        <v-btn @click="closeDialog">
                            Kembali
                        </v-btn>
                    </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>
<script>
import axios from 'axios'

export default {
    props: ['showDialog', 'labelName', 'broadcastId'],

    components: {},

    data: function() {
        return {
            term: String(),
            totalElements: 0,

            cloneTitle: String(),
            
            formValid: false,
            isLoading: false,

            headers: [
                { text: 'ID', value: 'id', sortable: false },
                { text: 'Nama', value: 'name', sortable: false },
                { text: 'Nomor', value: 'primaryPhoneNumber', sortable: false },
                { text: 'Aksi', value: 'action', sortable: false },
            ],

            options: Object(),

            desserts: Array(),

            rules: {
                required: [(v) => !!v || ""],
                requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                requiredArray: [(v) => v.length > 0 || ""],
            },
        }
    },

    methods: {
        goRoomChat: function(item) {
            const queryParams = {
                status: item.status,
                sortOrder: 'lastActive',
                sortDir: 'desc',
                custroles: '',
                labels: '',
                lastMessagesDirection: '',
            }
            
            if(item.status == 'open') {
                this.$router.push({
                    name: 'myconversation',
                    params: { id: encodeURIComponent(item.id) },
                    query: queryParams
                });
            } else {
                this.$router.push({
                    name: 'allmessage',
                    params: { id: encodeURIComponent(item.id) },
                    query: queryParams
                });
            }
        },
        resetFilter: async function() {
            this.term = String()

            await this.detail(this.labelName)
        },
        handleSearch: async function() {
            this.options.page = 1
            await this.detail(this.labelName)
        },
        detail: async function(labelName) {
            this.isLoading = true

            let params = Object();

            params.term = this.term
            if (this.options && this.options.page)  params.page = this.options.page;
            if (this.options && this.options.itemsPerPage) params.size = this.options.itemsPerPage;

            try {
                const { data } = await axios.get(process.env.VUE_APP_API_URL + '/label/contacts/'+ labelName, {
                    params
                })

                this.desserts = data.content

                this.totalElements = data.page.totalElements
            } catch (error) {
                console.log('detail-label', error);
            }
            this.isLoading = false
        },
        closeDialog: function() {
            this.desserts = Array()
            this.$emit('close-dialog')
        },
    },

    watch: {
        options: {
            handler() {
                if(this.showDialog) this.detail(this.labelName);
            },
            deep: true,
        },
        showDialog: function(val) {
            if(val) {
                this.detail(this.labelName)
            } else {
                this.term = String()
            }
        }
    },
}

</script>
