<template>
    <div class="row">
        <div class="col-12">
            <div class="card card m-b-0">
                <div class="chat-main-box">
                    <div class="chat-right-aside" style="width:100%">
                        <div class="chat-main-header">
                        </div>
                        <div class="chat-rbox static-content">
                            <div class="wrapp-chat-content" data-scrollbar="true" tabindex="1"
                                style="overflow: hidden; outline: none;">
                                <div class="scroll-content">
                                    <div class="contact-add">
                                        <form class="form-horizontal form-material">
                                            Daftar Session
                                            <!-- <router-link :to="{ name: 'broadcast' , query: { tabs: 'add' } }" v-if="showPage == 'list'">
                                                <v-btn class="btn btn-primary pull-right text-white btn btn-primary">
                                                    Buat Broadcast
                                                </v-btn>
                                            </router-link> -->
                                            <div class="clearfix"></div>
                                            <br>
                                            <div class="clearfix"></div>
                                            <div class="wrapp-input-contact m-t-20">
                                                <template>
                                                    <div class="list-auto-template">
                                                        <v-row>
                                                            <v-col cols="2">
                                                                <v-text-field label="Nama / Id Pengguna" v-model="term"/>
                                                            </v-col>
                                                            <v-col cols="2">
                                                                <v-autocomplete class="label-label mb-0"
                                                                    v-model="selectedOperator"
                                                                    :items="operatorList" 
                                                                    hide-selected
                                                                    item-text="name"
                                                                    item-value="_id"
                                                                    label="Operator"
                                                                >
                                                                </v-autocomplete>
                                                            </v-col>
                                                            <v-col cols="2">
                                                                <v-select 
                                                                    v-model="selectedDate" 
                                                                    :items="dateTypes" 
                                                                    label="Tipe Tanggal"
                                                                    item-value="value"
                                                                    item-text="text">
                                                                </v-select>
                                                            </v-col>
                                                            <v-col cols="2" class="pt-7">
                                                                <v-menu ref="startDate" v-model="isOpenStartDate" :close-on-content-click="false" :nudge-right="40"
                                                                    :return-value.sync="selectedStartDate" transition="scale-transition" min-width="290px" offset-y>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-text-field :value="selectedStartDate ? utcToLocal(selectedStartDate,'DD-MM-YYYY') : ''"
                                                                        class="" label="Tanggal Mulai" readonly dense hide-details v-on="on" />
                                                                    </template>
                                                                    <v-date-picker v-model="startDate" no-title scrollable :max="selectedEndDate ? selectedEndDate : ''"
                                                                        @input="$refs.startDate.save(startDate)" />
                                                                </v-menu>
                                                            </v-col>
                                                            <v-col cols="2" class="pt-7">
                                                                <v-menu ref="endDate" v-model="isOpenEndDate" :close-on-content-click="false" :nudge-right="40"
                                                                :return-value.sync="selectedEndDate" transition="scale-transition" min-width="290px" offset-y>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-text-field readonly dense hide-details
                                                                    :value="selectedEndDate ? utcToLocal(selectedEndDate,'DD-MM-YYYY') : ''" class=""
                                                                    label="Tanggal Selesai" v-on="on" />
                                                                </template>
                                                                <v-date-picker v-model="endDate" no-title scrollable :min="selectedStartDate ? selectedStartDate : ''"
                                                                    @input="$refs.endDate.save(endDate)" />
                                                                </v-menu>
                                                            </v-col>
                                                            <v-col cols="2">
                                                                <v-select v-model="selectedStatus" :items="status" label="Status"></v-select>
                                                            </v-col>
                                                            <v-col cols="2">
                                                                <v-checkbox v-model="isOperatorNull" label="Operator Kosong"></v-checkbox>
                                                            </v-col>
                                                            <v-col cols="3" class="pt-7">
                                                                <v-btn @click="handleSearch" class="mr-2 text-white btn btn-primary">
                                                                    Cari
                                                                </v-btn>
                                                                <v-btn @click="resetFilter">
                                                                    Reset
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="4" class="pb-0">
                                                                <v-select v-model="selectedField" :items="itemField"
                                                                    label="Tampilkan Kolom" multiple>
                                                                    <template v-slot:selection="{ item, index }">
                                                                        <div v-if="index === 0" class="mr-1">
                                                                            <span>{{ item }}</span>
                                                                        </div>
                                                                        <span v-if="index === 1"
                                                                            class="grey--text text-caption">
                                                                            (+{{ selectedField.length - 1 }} lainnya)
                                                                        </span>
                                                                    </template>
                                                                </v-select>
                                                            </v-col>
                                                        </v-row>
    
                                                        <div class="my-5">
                                                            <v-data-table :headers="headers" :items="sessionList"
                                                                :options.sync="options" :server-items-length="totalElements"
                                                                class="elevation-1" :loading="isLoading"
                                                                :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
                                                            >
                                                                <template v-slot:item.status="{ item }">
                                                                    <div >
                                                                        {{ item.status == 'DRAFT' ? 'Belum Ditangani' : item.status == 'ACTIVE' ? 'Ditangani': 'Ditutup' }}
                                                                    </div>
                                                                </template>
                                                                <template v-slot:item.createdOn="{ item }">
                                                                    <div >
                                                                        {{ item.inTime }}
                                                                    </div>
                                                                </template>
                                                                <template v-slot:item.lastMessageTime="{ item }">
                                                                    <div >
                                                                        {{ item.lastTime }}
                                                                    </div>
                                                                </template>
                                                                <template v-slot:item.handledMessageTime="{ item }">
                                                                    <div >
                                                                        {{ item.onTime }}
                                                                    </div>
                                                                </template>
                                                                <template v-slot:item.closingMessageTime="{ item }">
                                                                    <div >
                                                                        {{ item.closeTime }}
                                                                    </div>
                                                                </template>
                                                                <template v-slot:item.order="{ item }">
                                                                    <div class="text-center" v-if="item.salesOrders.length > 0">
                                                                        <v-menu offset-y>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <a
                                                                                    color="primary"
                                                                                    dark
                                                                                    v-bind="attrs"
                                                                                    v-on="on"
                                                                                >
                                                                                    {{ item.salesOrders.length }}
                                                                                    No Penjualan
                                                                                    <i class="mdi mdi-chevron-down"></i>
                                                                                </a>
                                                                            </template>
                                                                            <v-list>
                                                                                <v-list-item v-for="order in item.salesOrders" :key="order.formalId">
                                                                                    <v-list-item-title>{{ order.formalId }}</v-list-item-title>
                                                                                </v-list-item>
                                                                            </v-list>
                                                                        </v-menu>
                                                                    </div>
                                                                </template>
                                                                <template v-slot:item.retur="{ item }">
                                                                    <div class="text-center" v-if="item.returnOrders.length > 0">
                                                                        <v-menu offset-y>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <a
                                                                                    color="primary"
                                                                                    dark
                                                                                    v-bind="attrs"
                                                                                    v-on="on"
                                                                                >
                                                                                    {{ item.returnOrders.length }}
                                                                                    No Pengembalian <i class="mdi mdi-chevron-down"></i>
                                                                                </a>
                                                                            </template>
                                                                            <v-list>
                                                                                <v-list-item v-for="retur in item.returnOrders" :key="retur.formalId">
                                                                                    <v-list-item-title>{{ retur.formalId }}</v-list-item-title>
                                                                                </v-list-item>
                                                                            </v-list> 
                                                                        </v-menu>
                                                                    </div>
                                                                </template>
                                                                <template v-slot:item.kinds="{ item }">
                                                                    <div class="text-center" v-if="item.kinds.length > 0 && item.isComplain">
                                                                        <i title="Pengguna Aktif" class="mdi mdi-check-circle" style="color:green;"></i>
                                                                    </div>
                                                                </template>
                                                            </v-data-table>

                                                            <v-spacer />
                                                            <v-menu rounded="lg" offset-y>
                                                                <template v-slot:activator="{ attrs, on }">
                                                                    <v-btn class="mt-6 mr-2" depressed
                                                                        :disabled="isLoading" v-bind="attrs"
                                                                        v-on="on">
                                                                        <v-icon color="primary" left dark>
                                                                            mdi-download
                                                                        </v-icon>
                                                                        Download
                                                                        <v-icon right dark>
                                                                            mdi-menu-down
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <v-list>
                                                                    <v-list-item @click="downloadSessionInfo">
                                                                        <v-list-item-title>Download as CSV
                                                                        </v-list-item-title>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-menu>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-x" style="display: none;">
                                    <div class="scrollbar-thumb scrollbar-thumb-x"
                                        style="width: 661px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-y" style="display: block;">
                                    <div class="scrollbar-thumb scrollbar-thumb-y"
                                        style="height: 445.289px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SuccessNotif />
    </div>
</template>
<script>
    import axios from 'axios'
    import Scrollbar from 'smooth-scrollbar'
    // import ErrorMessage from '../components/ErrorMessage'
    import MainLayout from "../layouts/MainLayout"
    import SuccessNotif from "../components/SuccessNotif"

    import { mapState, mapActions } from 'vuex'

    // const $ = require("jquery")
    const {
        commonpagesetup,
        mainuisetup
    } = require('../utils/ui-setup')
    var moment = require('moment');

    const {
        sessionDateTime
    } = require('../utils/date-utils');

    const NO_ASSIGN = 'Belum Ditangani'
    const ASSIGN = 'Ditangani'
    const CLOSE = 'Ditutup'

    const CONTACT_NAME = 'Pesan Dari'
    const OPERTATOR_NAME = 'Ditangani Oleh'
    const STATUS = 'Status'
    const CREATED_ON = 'Chat Masuk'
    const LAST_MSG_TIME = 'Chat Terakhir Masuk'
    const HANDLE_MSG_TIME = 'Chat Ditangani'
    const CLOSE_MSG_TIME = 'Chat Ditutup'
    const NO_ORDER = 'No Penjualan'
    const NO_RETURN = 'No Pengembalian'
    const COMPLAIN = 'Komplain'

    import { devServer } from "../../vue.config";

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo,
                broadcastCallback: state => state.socket.broadcastCallback,
                operatorList: state => state.operator.operatorList,
            })
        },
        data: function () {
            return {
                isOperatorNull: false,
                formValid: false,
                term: String(),
                selectedStatus: String(),
                selectedOperator: String(),
                selectedDate: String(),
                title: String(),
                status: [NO_ASSIGN, ASSIGN, CLOSE],
                isOpenStartDate: false,
                isOpenEndDate: false,
                selectedStartDate: String(),
                selectedEndDate: String(),
                startDate: String(),
                endDate: String(),
                selectedField: [CONTACT_NAME, OPERTATOR_NAME, STATUS, CREATED_ON, LAST_MSG_TIME, HANDLE_MSG_TIME, CLOSE_MSG_TIME, NO_ORDER, NO_RETURN, COMPLAIN],
                itemField: [CONTACT_NAME, OPERTATOR_NAME, STATUS, CREATED_ON, LAST_MSG_TIME, HANDLE_MSG_TIME, CLOSE_MSG_TIME, NO_ORDER, NO_RETURN, COMPLAIN],
                headers: [
                    { text: CONTACT_NAME, value: 'contactName', sortable: false },
                    { text: OPERTATOR_NAME, value: 'operatorName', sortable: false },
                    { text: STATUS, value: 'status', sortable: false },
                    { text: CREATED_ON, value: 'createdOn', sortable: false },
                    { text: LAST_MSG_TIME, value: 'lastMessageTime', sortable: false },
                    { text: HANDLE_MSG_TIME, value: 'handledMessageTime', sortable: false },
                    { text: CLOSE_MSG_TIME, value: 'closingMessageTime', sortable: false },
                    { text: NO_ORDER, value: 'order', sortable: false, align: 'center' },
                    { text: NO_RETURN, value: 'retur', sortable: false, align: 'center' },
                    { text: COMPLAIN, value: 'kinds', sortable: false, align: 'center' },
                ],
                dateTypes: [
                    { text: "Chat Masuk", value: "createdOn" },
                    { text: "Chat Ditangani", value: "handledMessageTime" },
                    { text: "Chat Ditutup", value: "closingMessageTime" },
                ],
                sessionList: Array(),
                totalElements: 0,
                options: Object(),
                isLoading: false,
                rules: {
                    required: [(v) => !!v || ""],
                    requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                    requiredArray: [(v) => v.length > 0 || ""],
                },
            }
        },
        watch: {
            options: {
                handler() {
                    this.loadSessionInfo();
                },
                deep: true,
            },
            sessionInfo: function (val) {
                if (!this.$helpers.isBlank(val) && val.isLoggedIn) {
                    this.loadInitialData();
                }
            },
            selectedField(val) {
                this.showFieldTable(val)
                localStorage.setItem('showselectedtablebpltk', val.toString())
            },
        },
        methods: {
            ...mapActions({
                initOperatorList: 'operator/initOperatorList'
            }),
            handleSearch() {
                this.options.page = 1
                this.loadSessionInfo()
            },
            showFieldTable(val) {
                const headers = [
                    { text: CONTACT_NAME, value: 'contactName', sortable: false },
                    { text: OPERTATOR_NAME, value: 'operatorName', sortable: false },
                    { text: STATUS, value: 'status', sortable: false },
                    { text: CREATED_ON, value: 'createdOn' },
                    { text: LAST_MSG_TIME, value: 'lastMessageTime' },
                    { text: HANDLE_MSG_TIME, value: 'handledMessageTime' },
                    { text: CLOSE_MSG_TIME, value: 'closingMessageTime' },
                    { text: NO_ORDER, value: 'order', sortable: false, align: 'center' },
                    { text: NO_RETURN, value: 'retur', sortable: false, align: 'center' },
                    { text: COMPLAIN, value: 'kinds', sortable: false, align: 'center' },
                ]
                
                this.headers = headers.filter(e => val.includes(e.text))
            },
            resetFilter: async function() {
                this.isOpenStartDate = false,
                this.isOpenEndDate = false,
                this.selectedStartDate = String()
                this.selectedEndDate = String()
                this.startDate = String(),
                this.endDate = String(),
                this.selectedStatus = String()
                this.term = String()
                this.selectedOperator = String()
                this.selectedDate = String()

                this.options.page = 1
                this.options.sortBy = Array()
                this.options.sortDesc = Array()

                await this.loadSessionInfo()
            },
            loadInitialData: async function () {
                this.loadSessionInfo()
            },
            downloadSessionInfo: function() {
                const qs = new URLSearchParams()
                qs.append("startDate", this.startDate ? moment(this.startDate).format() : String())
                qs.append("endDate", this.endDate ? moment(this.endDate).endOf('day').format() : String())
                qs.append("status", this.selectedStatus == NO_ASSIGN ? 'DRAFT' : this.selectedStatus == ASSIGN ? 'ACTIVE' : this.selectedStatus == CLOSE ? 'INACTIVE' : '')
                qs.append("sort", 'createdOn')
                qs.append("sortOrder", 'desc')
                qs.append("term", this.term)
                qs.append("operatorId", this.selectedOperator)
                qs.append("dateType", this.selectedDate)
                
                if (this.options && this.options.page) qs.append("page", this.options.page)
                if (this.options && this.options.itemsPerPage) qs.append("size", this.options.itemsPerPage)

                const params = qs.toString()

                window.open(devServer.proxy + '/sessioninfo/export/csv?' + params)
            },
            loadSessionInfo: async function() {
                this.isLoading = true
                let params = Object();
                params.startDate = this.startDate ? moment(this.startDate).format() : String()
                params.endDate = this.endDate ? moment(this.endDate).endOf('day').format() : String()
                params.status = this.selectedStatus == NO_ASSIGN ? 'DRAFT' : this.selectedStatus == ASSIGN ? 'ACTIVE' : this.selectedStatus == CLOSE ? 'INACTIVE' : ''
                params.sort = 'createdOn'
                params.sortOrder = 'desc'
                params.term = this.term
                params.operatorId = this.selectedOperator,
                params.dateType = this.selectedDate
                params.isOperatorNull = this.isOperatorNull

                if (this.options && this.options.page) params.page = this.options.page;
                if (this.options && this.options.itemsPerPage) params.size = this.options.itemsPerPage;
                if (this.options && this.options.sortBy && this.options.sortBy.length > 0) params.sort = this.options.sortBy[0];
                if (this.options && this.options.sortDesc && this.options.sortDesc.length > 0) {
                    params.sortOrder = this.options.sortDesc[0] ? 'desc' : 'asc';
                }

                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/sessioninfo', {
                        params
                    })
                    
                    this.sessionList = data.content.map(e => {
                        e.inTime = e.createdOn ? sessionDateTime(e.createdOn) : String()
                        e.onTime = e.handledMessageTime ? sessionDateTime(e.handledMessageTime) : String()
                        e.closeTime = e.closingMessageTime ? sessionDateTime(e.closingMessageTime) : String()
                        e.lastTime = e.lastMessageTime ? sessionDateTime(e.lastMessageTime) : String()
                        e.isComplain = false

                        if(e.kinds.length > 0) {
                            e.kinds.forEach(el => {
                                if(el.refMsgIds.length > 0 && el.contentType == 'complain') e.isComplain = true
                            });
                        }

                        return e
                    })

                    this.totalElements = data.page.totalElements
                } catch (error) {
                    console.log("load-broadcast", error);
                }
                this.isLoading = false
            },
            utcToLocal: function(date, format) {
                var utcLocal = moment(date).local().format(format);
                return utcLocal;
            },
            utcToLocalView: function(date, format) {
                var utcLocal = moment(date).format(format);
                return utcLocal;
            },
            localToUtc: function(date, format) {
                var utcLocal = moment(date).utc().format(format);
                return utcLocal;
            },
        },
        created: function () {
            this.$emit('update:layout', MainLayout);
        },
        mounted: function () {
            Scrollbar.initAll();
            if (!this.$helpers.isBlank(this.sessionInfo) && this.sessionInfo.isLoggedIn) {
                this.loadInitialData();
            }
            try {
                mainuisetup();
                commonpagesetup();
            } catch (err) {
                console.log(err);
            }

            if (localStorage.getItem('showselectedtablebpltk')) {
                let selectedFilterTable = localStorage.getItem('showselectedtablebpltk')
                this.selectedField = selectedFilterTable.split(",");
            }
        },
        components: {
            // ErrorMessage,
            SuccessNotif,
        }
    }
</script>